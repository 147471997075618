import Vue from 'vue'
import Router from 'vue-router'
// import router_view from '@/router_view'

import home from '@/pages/home/index'

import about_us from '@/pages/about_us/index'
import sponsorship from '@/pages/sponsorship/index'
import member from '@/pages/member/index'
import certificate from '@/pages/certificate/index'

import competition_information from '@/pages/competition/information'
import competition_venue from '@/pages/competition/venue'
import competition_school from '@/pages/competition/school'
import competition_group from '@/pages/competition/group'
import competition_match from '@/pages/competition/match'
import competition_top_scorer from '@/pages/competition/top_scorer'
import competition_best_player from '@/pages/competition/best_player'
import competition_ladder from '@/pages/competition/ladder'
import competition_cutoff from '@/pages/competition/cutoff'
import competition_tournament from '@/pages/competition/tournament'

import registration_school from '@/pages/payment/registration_school'
import registration_club from '@/pages/payment/registration_club'
import choose_payment from '@/pages/payment/choose_payment'
import login from '@/pages/auth/login'
import register from '@/pages/auth/register'

Vue.use(Router)

var router = new Router({
  routes: [
    {
      path: '',
      component: home,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/about-us',
      component: about_us,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/sponsorship',
      component: sponsorship,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/member',
      component: member,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/certificate',
      component: certificate,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/competition/information',
      component: competition_information,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/competition/venue',
      component: competition_venue,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/competition/school',
      component: competition_school,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/competition/group',
      component: competition_group,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/competition/match',
      component: competition_match,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/competition/top-scorer',
      component: competition_top_scorer,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/competition/best-player',
      component: competition_best_player,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/competition/ladder',
      component: competition_ladder,
      meta: {
        requiresAuth: false
      },
    },
		{
			path: '/competition/cutoff',
			component: competition_cutoff,
			meta: {
				requiresAuth: false
			},
		},
    {
      path: '/competition/tournament',
      component: competition_tournament,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/registration/school',
      component: registration_school,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/registration/club',
      component: registration_club,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/registration/choose-payment',
      component: choose_payment,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/auth/login',
      component: login,
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/auth/register',
      component: register,
      meta: {
        requiresAuth: false
      },
    },
  ],
  mode: 'history',
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      sessionStorage.setItem('redirect',to.fullPath)
      next({
        path: '/auth/login',
      })
    }
    else
      next()
  }
  else
    next()
})

export default router
