<template>
  <div class="">
    <div class="bg-primary">
      <NavbarDetail :title="competition_data.category_sport != null && competition_data.category_sport.name != null ? competition_data.category_sport.name : ''" />
    </div>
    <CompetitionTab :competition_data="competition_data"/>

    <div class="my-5 pb-5 container">
      <div class="py-3">
        <div class="mb-3" v-for="(venue, index) in competition_data.arr_venue" :key="index">
          <h4 class="m-0 text-primary font-weight-bold">{{index+1 + ". " + venue.venue.name }}</h4>
          <p class="m-0 competition-detail-category-td" v-html="venue.venue.address != null ? venue.venue.address : '-'"></p>
          <div class="row">
            <div class="col-12 col-md-6" v-for="(field, index1) in venue.arr_field" :key="index1">
              <div class="d-flex justify-content-between">
                <p class="m-0 competition-detail-venue-court">{{ field.court }}</p>
              </div>
            </div>
          </div>
          <div class="breakline"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Base from '@/utils/base';

import NavbarDetail from '@/layout/navbar_detail.vue'
import CompetitionTab from '@/pages/competition/tab.vue'

export default {
  components: {
    'NavbarDetail': NavbarDetail,
    'CompetitionTab': CompetitionTab,
  },
  data() {
    return {
      base: null,
      arr_factor: [false, ],
      competition_data: {},
    }
  },
  watch: {
    arr_factor(val) {
      this.$emit('onChangeArrFactor', val)
      this.manage_start_animation()
    },
  },
  created() {
    this.base = new Base()
    // this.arr_factor = [true, true, true, true,]

    this.get_competition_data()
  },
  methods: {
    async get_competition_data(){
      var response = await this.base.request(this.base.url_api + "/event/category-sport?id=" + this.$route.query.id)
      this.$set(this.arr_factor, 0, true)

      if(response != null){
        if(response.status === "success"){
          var arr = []
          for(let x in response.data.venue){
            var index = -1
            var data = {}
            for(let y in arr){
              if(arr[y].venue.id == response.data.venue[x].venue.id){
                index = y
                data = arr[y]
                break
              }
            }

            if(index < 0)
              data = {
                venue: response.data.venue[x].venue,
                arr_field: [],
              }
            data.arr_field.push({
              court: response.data.venue[x].court,
            })

            if(index < 0)
              arr.push(data)
            else
              arr[index] = data
          }

          response.data.arr_venue = arr
          this.competition_data = response.data
        }
        else
          this.base.show_error(response.message)
      }
      else
        console.log(this.$t('server_error'))
    },
  }
}
</script>

<style lang="scss">
.competition-detail-venue-court{
  font-family: 'montserrat-regular';
  color: $primary;
}

.custom-title {
  color: $primary;
  font-family: poppins-bold;
}

.about-us-medal-detail {
  color: $gray20;
}

.trust-card {
  background-color: $gray9;
  border: none;
}

.title-section {
  font-size: 2rem;
  font-family: poppins-medium;
}

.content-section {
  color: $gray6;
}

.team-role {
  color: $black1;
}

.team-name {
  color: $black1;
  font-family: poppins-bold;
}

.about-us-title1-enter-active,
.about-us-title1-leave-active,
.about-us-title2-enter-active,
.about-us-title2-leave-active,
.about-us-title3-enter-active,
.about-us-title3-leave-active {
  transition: all 2s;
}

.about-us-title1-leave-to,
.about-us-title1-enter,
.about-us-title2-leave-to,
.about-us-title2-enter,
.about-us-title3-leave-to,
.about-us-title3-enter {
  transform: translateX(-10rem);
  opacity: 0;
}

.about-us-content1-enter-active,
.about-us-content1-leave-active,
.about-us-content2-enter-active,
.about-us-content2-leave-active,
.about-us-content3-enter-active,
.about-us-content3-leave-active {
  transition: all 2s;
}

.about-us-content1-leave-to,
.about-us-content1-enter,
.about-us-content2-leave-to,
.about-us-content2-enter,
.about-us-content3-leave-to,
.about-us-content3-enter {
  transform: translateX(10rem);
  opacity: 0;
}

.about-us-image1-enter-active,
.about-us-image1-leave-active,
.about-us-image2-enter-active,
.about-us-image2-leave-active,
.about-us-image3-enter-active,
.about-us-image3-leave-active {
  transition: all 2s;
}

.about-us-image1-leave-to,
.about-us-image1-enter,
.about-us-image2-leave-to,
.about-us-image2-enter,
.about-us-image3-leave-to,
.about-us-image3-enter {
  // margin-left: 10rem !important;
  opacity: 0;
}

#about-us-content img {
  width: 100%;
  border-radius: 1rem;
}

.trust-list>p:nth-child(1) {
  min-height: 100px;
  text-align: center;
}

.trust-list>p:nth-child(2) {
  text-align: justify;
  text-align-last: center;
}

.table-member {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
  font-size: 0.75rem;
}

.table-member>tr {
  display: table;
  width: 100%;
}

.btn .caret {
  position: absolute;
  top: calc(50% - 1px);
}

// .form-control{
//   background-position: right .75rem center;
// }
</style>
